// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("./loginbg.jpg");
exports = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
exports.push([module.id, ".login[data-v-6f097ef5]{height:100vh;background-image:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");background-size:cover;display:flex;justify-content:center;align-items:center}.form[data-v-6f097ef5]{width:400px;background:hsla(0,0%,100%,.2);border:1px solid #e3e3e3;border-radius:5px;padding:20px}.img-1[data-v-6f097ef5]{width:100px;height:100px;margin:10px auto 20px;display:block;border-radius:50%}", ""]);
// Exports
module.exports = exports;
